/* --------------------
  Polices
 ---------------------*/
@font-face {
    font-family: 'Gruppo';
    src: url('../fonts/gruppo.eot');
    src: url('../fonts/gruppo.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gruppo.woff') format('woff'),
    url('../fonts/gruppo.ttf') format('truetype'),
    url('../fonts/gruppo.svg#Gruppo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shadows Into Light';
    src: url('../fonts/shadows_into_light.eot');
    src: url('../fonts/shadows_into_light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/shadows_into_light.woff') format('woff'),
    url('../fonts/shadows_into_light.ttf') format('truetype'),
    url('../fonts/shadows_into_light.svg#Shadows+Into+Light') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* --------------------
  Surcharges globales
 ---------------------*/
/* Titres */
h1, h2, h3 {
    font-family: 'Gruppo', cursive;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.7rem;
}

a {
    text-decoration: none;
    color: #337ab7;
}

a:focus,
a:hover {
    text-decoration: underline 1px;
    color: #23527c;
}

/* Couleurs de fond */
.bg-info {
    background-color: #428BCA !important;
}

/* Boutons */
.btn-primary {
    background-color: #428BCA;
}

.btn-primary:hover {
    background-color: #286090;
    border-color: #204d74;
}

/* Cards */
.card-header {
    margin-bottom: 0;
    padding: 15px;
    font-family: 'Gruppo', cursive;
}

.content-body {
    padding: 15px;
}

.content-infos {
    padding: 0 15px;
}

/* Listes */
ul.no-style {
    padding-left: 25px;
}

ul.no-style > li {
    list-style: none;
}

ul.inline > li {
    display: inline;
}

li.col-lg-1,
li.col-lg-2,
li.col-lg-3,
li.col-lg-4,
li.col-lg-5,
li.col-lg-6,
li.col-lg-7,
li.col-lg-8,
li.col-lg-9,
li.ol-lg-10,
li.col-lg-11,
li.col-lg-12 {
    padding-left: 0;
}

dt {
    margin-top: 25px;
    margin-bottom: 15px;
    padding-top: 20px;
    border-top: 1px solid #EEEEEE;
}

dl dt:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0 none;
}

hr {
    margin: 50px 0 20px;
    color: #CCCCCC;
}

/* Message et couleurs de succès */
.has-success .help-block,
.has-success .control-label {
    color: #62a720;
}

.has-success .form-control {
    border-color: #62a720;
}

/* Autres */
.clear {
    clear: both;
}

span.icon {
    vertical-align: middle;
}

.content-body img {
    max-width: calc(100% - 10px);
    margin: auto;
}

/* --------------------
	Bloc de code
 ---------------------*/

li code,
p code {
    display: inline;
}

pre {
    border: 0 none;
}

code {
    display: block;
    background-color: #F5F5F5;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    line-height: 1.42857;
    white-space: pre-wrap;
    word-break: normal;
}

pre, code {
    overflow: auto;
    margin: 10px 0;
    white-space: pre;
    color: #606060;
}

code[class*="language-"], pre[class*="language-"] {
    background-color: #f5f5f5;
    margin: 0;
    border: 0 none;
}

pre[class*="language-"] {
    display: block;
    overflow: auto;
    margin: 0.5em;
    padding: 0.5em;
    line-height: 1.3;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #222;
}


/* --------------------
	Tableaux
 ---------------------*/
.content-body table {
    width: calc(100% - 1em);
    margin: 0.5em;
    border: 1px solid #cccccc;
}

.content-body table tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

.content-body table tr:hover {
    background-color: #f8f8f8;
}

.content-body table th,
.content-body table td {
    padding: 5px;
    border: 1px solid #cccccc;
}

.content-body table th {
    text-align: center;
    background-color: #f8f8f8;
}

/* ------------------------
		Pager
 ----------------------- */
.pager li.is-active a {
    color: #000000;
    background-color: #f5f5f5;
}

/* ------------------------
		Messages
 ----------------------- */
.messages__wrapper {
    margin-top: 0;
    padding-top: 0;
}

.messages--info {
    padding-left: 20px;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1 #bce8f1 #bce8f1 transparent; /* LTR */
    background-image: none;
    box-shadow: -8px 0 0 #1b809e; /* LTR */
}

[dir="rtl"] .messages--info {
    border-color: #bce8f1 transparent #bce8f1 #bce8f1;
    box-shadow: 8px 0 0 #1b809e;
    margin-left: 0;
}
