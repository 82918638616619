/* --------------------
	Conteneur Global
 ---------------------*/
body {
    background-color: #ADADAD;
}

#main-container {
    overflow: hidden;
    padding: 0 20px;
    background-color: #FFFFFF;
}


/* --------------------
		HEADER
 ---------------------*/
.main-header {
    margin-bottom: 20px;
}

.main-header .page-header {
    margin: 0 0 20px;
    padding-top: 40px;
    border-color: #ADADAD;
    border-bottom: 1px solid #ADADAD;
}

.main-header .page-header .title-container {
    padding-left: 40px;
}

.main-header h1 {
    font-size: 5em;
    font-family: 'Shadows Into Light', cursive;
}

.main-header .lead {
    font-size: 2em;
    font-family: 'Shadows Into Light', cursive;
}

.main-header .page-header a {
    color: #000000;
    text-decoration: none;
}

.main-header .breadcrumb {
    background-color: #f5f5f5;
    border-radius: 4px;
    list-style: none;
    margin-bottom: 20px;
    padding: 8px 15px;
}

/* --------------------
	MENU PRINCIPAL
 ---------------------*/
/* Menu principal */
ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
}

a.menu:after,
.dropdown-toggle:after {
    content: none;
}

/* --------------------
	COLONNE DE GAUCHE
 ---------------------*/

#col-left h1 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
}

#col-left h1 small {
    font-size: 1em;
    font-weight: normal;
}

/* Barre de recherche */
#search-bar {
    margin: 10px 0 24px;
}

#search-bar label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

/* Cloud de rubriques */
#cloud-rubrique ul {
    padding: 10px;
}

#cloud-rubrique ul li {
    padding: 0 3px;
}

#cloud-rubrique ul li a:hover {
    color: #000000;
}

#cloud-rubrique .size-0 a {
    color: #535548;
    font-size: 0.7em;
}

#cloud-rubrique .size-1 a {
    color: #535548;
    font-size: 0.9em;
}

#cloud-rubrique .size-2 a {
    font-size: 1.1em;
}

#cloud-rubrique .size-3 a {
    font-size: 1.3em;
}

#cloud-rubrique .size-4 a {
    color: #C15E5B;
    font-size: 1.5em;
}

#cloud-rubrique .size-5 a {
    color: #C15E5B;
    font-size: 1.7em;
}

#display-parameters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}


@media all and (min-width: 0px) and (max-width: 600px) {
    #cloud-rubrique .size-1 a,
    #cloud-rubrique .size-2 a,
    #cloud-rubrique .size-3 a,
    #cloud-rubrique .size-4 a,
    #cloud-rubrique .size-5 a {
        font-size: 1.5em;
    }
    #cloud-rubrique .size-0 a {
        font-size: 1.2em;
    }
}
