/* -------------------------------------------------
  Affichage d'un contenu listant d'autres contenus
 ------------------------------------------------ */
.main-content header h1 {
    margin: 0;
}

.main-content.class-category header h1 {
    padding-bottom: 17px;
}

.main-content header .content-infos {
    min-height: initial;
    margin: 0;
}

/* -------------------------------
  Affichage d'un contenu unique
 ------------------------------ */
.main-content .unique header h1 {
    margin: 0;
}

/* ---------------------------------------
	Affichage d'un contenu en mode liste
 -------------------------------------- */
.view-list:not(:last-of-type) {
    margin-bottom: 20px;
}

.view-list header h1,
.view-list header h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2em;
    line-height: 1.1em;
}

.list-contents .view-list {
    margin-top: 30px;
}

/* ---------------------------------------
	Affichage d'un contenu Article
 -------------------------------------- */
.class-article .content-image {
    max-width: 100%;
    margin: auto;
}

.class-article .content-image img {
    max-width: 100%;
}

.class-article .tag-container img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

/* ------------------------------------
   Infos complémentaires d'un contenu
 ----------------------------------- */
.content-infos {
    min-height: 30px;
}

.content-infos p,
.content-infos .float-end {
    margin-left: 10px;
    text-align: right;
}

.card-header .content-infos .float-end {
    margin-bottom: 0;
}

.content-infos a {
    margin-left: 5px;
}

